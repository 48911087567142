import React from 'react';
import { AppBar, Toolbar, Typography, Box, Grid } from '@mui/material';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'; // Import NavLink for active functionality
import './Header.css'; // Custom styles
import Logo from '../images/logo.jpg';

const Header = () => {
    return (
        <>
            {/* AppBar with Logo and Text */}
            <AppBar position="static" style={{ background: '#fff', boxShadow: 'none' }}>
                <Container>
                    <Toolbar>
                        <Grid container alignItems="center" justifyContent="space-between">
                            {/* Logo on the left with name below */}
                            <Grid item>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <img
                                        src={Logo}
                                        alt="Logo"
                                        style={{
                                            height: '120px',
                                            marginRight: '20px',
                                            marginTop: '5px',
                                            boxShadow: '2px 2px 2px 2px black'
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        style={{ color: '#333', fontWeight: 'bold', marginTop: '10px', fontSize: '14px' }}
                                    >
                                        || जनसेवा हिच ईश्वर सेवा ||
                                    </Typography>
                                </Box>
                            </Grid>
                            {/* Text on the right */}
                            <Grid item xs={12} sm={6} md={8}>
                                <Box textAlign="center">
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: '#b0580f',
                                            fontWeight: 'bold',
                                            fontSize: '27px',
                                            textShadow: '1px 1px 2px black', // Added blue shadow
                                        }}
                                    >
                                        संत गाडगे महाराज शिक्षण सांस्कृतिक क्रिडा मंडळ
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: '#b0580f',
                                            fontWeight: 'bold',
                                            fontSize: '27px',
                                            textShadow: '1px 1px 2px black', // Added blue shadow
                                        }}
                                    >
                                        Sant Gadge Maharaj Shikshan Sanskrutik Krida Mandal
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Full-width Navigation Bar */}
            <nav className="navbar navbar-expand-lg navbar-light bg-custom">
                <Container>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav w-100 d-flex justify-content-end"> {/* Right-aligned */}
                            <li className="nav-item">
                                <NavLink exact to="/" className="nav-link" activeClassName="active">
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/about" className="nav-link" activeClassName="active">
                                    About
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link" activeClassName="active">
                                    Contact
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gallery" className="nav-link" activeClassName="active">
                                    Gallery
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/latest-news" className="nav-link" activeClassName="active">
                                    Latest News
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </Container>
            </nav>
        </>
    );
};

export default Header;
