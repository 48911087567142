import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Gallary from './components/Gallary';
import LatestNews from './components/LatestNews';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './components/About';
import Footer from './components/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

const App=()=> {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallary />} />
        <Route path="/latest-news" element={<LatestNews />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;