import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './Home.css'; // Add this file for custom styles
import image1 from '../images/1.jpeg'; // Replace with your actual image paths
import image2 from '../images/2.jpeg';
import image3 from '../images/logo2.jpg';
import image4 from '../images/logo3.png';

const Home = () => {
    return (
        <Container className="carousel-container mt-4">
            <Carousel>
                <Carousel.Item interval={3000}>
                    <img
                        className="d-block w-100"
                        src={image1}
                        alt="First slide"
                        style={{ height: '500px', objectFit: 'cover' }}
                    />
                    <Carousel.Caption>
                        <h3>First Slide</h3>
                        <p>Description for first slide.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img
                        className="d-block w-100"
                        src={image2}
                        alt="Second slide"
                        style={{ height: '500px', objectFit: 'cover' }}
                    />
                    <Carousel.Caption>
                        <h3>Second Slide</h3>
                        <p>Description for second slide.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img
                        className="d-block w-100"
                        src={image3}
                        alt="Third slide"
                        style={{ height: '500px', objectFit: 'cover' }}
                    />
                    <Carousel.Caption>
                        <h3>Third Slide</h3>
                        <p>Description for third slide.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img
                        className="d-block w-100"
                        src={image4}
                        alt="Fourth slide"
                        style={{ height: '500px', objectFit: 'cover' }}
                    />
                    <Carousel.Caption>
                        <h3>Fourth Slide</h3>
                        <p>Description for fourth slide.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Container>
    );
};

export default Home;
