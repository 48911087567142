import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './LatestNews.css'; // Custom styles for the page

const LatestNews = () => {
  // Sample news data
  const newsData = [
    {
      id: 1,
      title: 'New Cultural Event Announced',
      image: 'https://via.placeholder.com/300x200', // Replace with actual image URLs
      description: 'A grand cultural event will be held next week, celebrating our rich heritage with performances and exhibitions.',
    },
    {
      id: 2,
      title: 'Sports Day Highlights',
      image: 'https://via.placeholder.com/300x200',
      description: 'The annual sports day was a success, with students participating in various competitions and showcasing their skills.',
    },
    {
      id: 3,
      title: 'New Academic Courses',
      image: 'https://via.placeholder.com/300x200',
      description: 'We are excited to announce new academic courses for the upcoming semester to enrich students’ learning experience.',
    },
    {
      id: 4,
      title: 'Community Service Initiatives',
      image: 'https://via.placeholder.com/300x200',
      description: 'Our students recently participated in a community service initiative to help clean up the local area and support those in need.',
    },
  ];

  return (
    <Container className="latest-news-page">
      <h2 className="text-center my-4">Latest News</h2>
      <Row>
        {newsData.map((news) => (
          <Col key={news.id} md={6} lg={4} className="mb-4">
            <Card className="news-card h-100">
              <Card.Img variant="top" src={news.image} alt={news.title} />
              <Card.Body>
                <Card.Title>{news.title}</Card.Title>
                <Card.Text>{news.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LatestNews;
