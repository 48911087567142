import React from 'react';

const About = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">About Sant Gadge Maharaj Shikshan Sanskrutik Krida Mandal</h2>
      
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="card shadow-lg p-4">
            <div className="card-body">
              <h4 className="card-title mb-3">Our Mission</h4>
              <p className="card-text">
                <strong>Sant Gadge Maharaj Shikshan Sanskrutik Krida Mandal</strong> is dedicated to promoting education, culture, and sports among the youth. Our mission is to nurture the next generation with values that emphasize cleanliness, education, sportsmanship, and cultural pride, following the teachings of Sant Gadge Baba.
              </p>
              
              <h4 className="card-title mb-3">What We Do</h4>
              <p className="card-text">
                Established in the spirit of Sant Gadge Baba’s legacy, we run various initiatives to improve society through education and extracurricular activities. We focus on:
              </p>
              <ul>
                <li>Providing quality education and building infrastructure for schools and colleges.</li>
                <li>Encouraging youth participation in cultural activities and sports.</li>
                <li>Organizing cleanliness and health awareness campaigns in rural and urban areas.</li>
                <li>Hosting sports events to promote physical health and team spirit among students.</li>
              </ul>
              
              <h4 className="card-title mb-3">Our Vision</h4>
              <p className="card-text">
                Our vision is to create a society where education, cultural heritage, and physical well-being are equally valued and accessible to everyone. We aim to empower the underprivileged sections of society by providing them with opportunities to grow through education and sports.
              </p>
              
              <h4 className="card-title mb-3">Get Involved</h4>
              <p className="card-text">
                We invite you to join us in our efforts to spread the message of Sant Gadge Baba. Whether through volunteering, donations, or active participation in our events, your support helps us reach more people and positively impact the community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
